import type { NamedSet } from 'zustand/middleware/devtools'

import { uuidv4 } from '@embed/helpers'

import type { AvaContext, AvaGlobalState } from './state'

export type AvaGlobalAction = ReturnType<typeof createAvaGlobalActions>

/**
 *
 */
export function createAvaGlobalActions(set: NamedSet<AvaGlobalState>) {
  return {
    resetContexts: (): void => set({ contexts: [] }, false, 'resetContexts'),
    addContext: (context: AvaContext): void =>
      set(
        (state) => {
          const newContexts = [
            ...state.contexts,
            {
              ...context,
              key: uuidv4(),
            },
          ]
          return { contexts: newContexts }
        },
        false,
        'addContext',
      ),
    removeContext: (key: string): void =>
      set(
        (state) => {
          const newContexts = state.contexts.filter(
            (context) => context.key !== key,
          )
          return { contexts: newContexts }
        },
        false,
        'removeContext',
      ),
  }
}
