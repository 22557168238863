import type { PIPParams } from '@core/pictureInPicture/types'
import type { AppContextType } from '@embed/components/helpers'

export type PlayerStyle = 'classic' | 'modern'

export type ButtonBorderStyle = 'default' | 'rounded' | 'hard'

export enum WidgetTypesEnum {
  BUTTON_WRAPPER = 'button_wrapper',
  EMBED_FEED = 'embed_feed',
  STORYBLOCK = 'storyblock',
  STORYLINK = 'storylink',
  HEROUNIT = 'herounit',
  CHANNEL_BUTTON = 'channel_button',
  ADD_TO_CALENDAR = 'add_to_calendar',
  SHARE = 'share',
  PROGRAMMATIC = 'programmatic',
  SHOWROOM_SCHEDULE = 'showroom_schedule',
  PLAYER = 'player',
}

export enum IPresentation {
  ADD_TO_CALENDAR = 'add_to_calendar',
  BUTTON_WRAPPER = 'button_wrapper',
  CAROUSEL = 'carousel',
  DYNAMIC_CAROUSEL = 'dynamic_carousel',
  HORIZONTAL_CAROUSEL = 'horizontal_carousel',
  PLAYER_DECK = 'player_deck',
  GRID = 'grid',
  HERO = 'hero',
  PLAYER = 'player',
  SHARE = 'share',
  STORYBLOCK = 'storyblock',
  STORYBLOCK_PRODUCT_GALLERY = 'storyblock_product_gallery',
  STORYLINK = 'storylink',
  SHOWROOM_SCHEDULE = 'showroom_schedule',
}

export enum PlayerPlacementEnum {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

export enum PlayerTitleAlignmentEnum {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum ResetTransformEnum {
  ALWAYS = 'always',
  FULLSCREEN = 'fullscreen',
  NEVER = 'never',
}

export enum OpenInEnum {
  DEFAULT = 'default',
  BLANK = '_blank',
  SELF = '_self',
  MODAL = '_modal',
  IFRAME = '_iframe',
  FALSE = 'false',
}

export enum ShareEnum {
  HASH = 'hash',
  QUERY = 'query',
  FALSE = 'false',
}

export enum ShoppingCartStyle {
  BAG = 'bag',
  CART = 'cart',
}

export enum UsernamePromptBehaviourEnum {
  OFF = 'off',
  CHAT = 'chat',
  ACTIVE = 'active',
  TRUE = 'true', //fallback for when livestream_prompt_username_change was a bool. "true" == "chat"
}

export type ProductClickHandlerType = 'internal' | 'external'

export type IParams = {
  ad_positions?: string
  ads_hide_badge?: boolean
  a11y_disable_focus?: boolean
  a11y_disable_previews?: boolean
  a11y_limit_previews?: boolean
  allow_duplicate_ads?: boolean
  api_host?: string
  api_host_ads?: string
  api_host_tracking?: string
  api_host_tracking_legacy?: string
  auto_apply_coupon_enabled?: boolean // [GROW-641] feature flag for auto_apply_coupon_code
  auto_subtitles_enabled?: boolean // [CS-7834] subtitles are enabled by default when video is inline. This toggles that
  autoplay?: boolean
  autoplay_keep_thumbnail?: boolean // [CS-3108] Determines whether to show thumbnail back on mouse leave
  branding?: boolean
  cart_revamp?: boolean // [GROW-360] show cart in video & trailer
  channel?: string
  ad_channel_id?: string
  channel_id?: string
  cookies_days?: number // [CS-4775] to set cookies expiration time (defaults to 365 days)
  cookies_opt_in?: boolean // [CS-4137] to turn on cookies opt-in management (when enabled, cookies postponed until consent is given)
  cookies_root?: boolean // [CS-4462] whether to set up tracking cookies for the root domain (defaults to `true`)
  cta_button_type?: string
  dir?: '' | 'ltr' | 'rtl'
  disable_auto_heart?: boolean
  email_embed?: boolean // [CS-4515] Configurable player menu
  expand_prod_desc_enabled?: boolean // [GROW-392] expand product description when user scrolls to bottom
  force_replay?: boolean // [CS-8144] Ignore video.replay_enabled
  full_height_pdp?: boolean // [GROW-1040] Full-height PDP
  fullscreen_pdp_enabled?: boolean // [GROW-827] Default value is false until it is green lit to be GA'd
  handle_product_card_click?: ProductClickHandlerType // [CS-6389] whether or not to handle product card clicks internally
  handle_product_cta_click?: ProductClickHandlerType // [CS-6389] whether or not to handle product CTA clicks internally
  hashtag?: string
  hide_cart_quantity?: boolean // [GROW-498] hide cart line item quantity
  hide_cart_total?: boolean // [GROW-498] hide cart total
  hide_product_price?: boolean // [GROW-631] Default value is true. Customers can now toggle to hide the product price when creating custom products in the business portal. This is a placeholder for a naboo flag to be added in case this feature needs to be turned off.
  hide_show_detail?: boolean // Hide `show detail` button in the product card. Requested in https://bit.ly/3qZE5N6.
  homepage_url?: string
  in_sdk_webview?: boolean
  key_moments?: boolean // Default value is false until the feature is turned on for all customers
  lang?: string // [CS-1025] set specific language for widget
  light_mode_pdp?: boolean // [COM-1820] Mobile pdp is updated to have a white background by default to test the impact on conversion
  livestream_allow_username_change?: boolean // [CS-4099] Allow users to disable username changing
  livestream_chat?: boolean // [CS-1292] to turn off chat for performance testing
  livestream_firstparty?: boolean // [CS-3532] Enable player in first party context regardless of video settings
  livestream_prompt_username_change?: UsernamePromptBehaviourEnum | null // [CS-2959] When to prompt users to enter a username
  livestream_username?: string // [CS-3216] Client wants to hydrate visitor's username
  location?: Location
  loop?: boolean // Player will loop the feed when end is reached (defaults to true)
  lsdesktop?: boolean
  max_videos?: number
  mode?: string
  moderator_icon?: string // [CS-2547] moderator icon src value
  muted?: boolean // [QA-1191] Whether to unmute on user interaction (fullscreen, thumbnail...). Note: Player always un-mutes on direct user interaction with mute button.
  name?: string
  open_in?: OpenInEnum
  overlay_play_button_size?: number
  page_type?: string
  page_url?: string
  persist_cart?: boolean // [GROW-324] to turn on cart persistency for clients w/o product hydration
  pip_mode?: 'iframe' | 'soft' // Widget's specific PIP mode for customers who want to use Soft PIP only. Empty or `iframe` value will use iframe to render original bellow the video for seamless experience.
  placement?: string
  player_add_to_calendar?: boolean // [CS-1500] to turn off the Add to calendar
  player_captions?: boolean // [CS-1479] to turn off the player caption
  player_close?: boolean // Whether the player can be closed
  player_detached?: boolean // [CS-1930] opt-in to render player for SPA like apps which will handle PIP by themselves
  player_fullscreen?: boolean
  player_fullscreen_contain?: boolean // Experimental param to force fullscreen player to render video with object-fit: contain
  player_key?: string
  player_margin?: string
  player_minimize?: boolean // Whether the player can be minimized
  player_more_menu?: boolean
  player_placement?: PlayerPlacementEnum
  player_play_pause?: boolean
  player_tap_to_unmute?: boolean
  player_tiles?: boolean
  player_unmute?: boolean // [CS-6031] Hide mute button for integrations where there is no audio expected
  player_vertical_scroll?: boolean // Enable native vertical scroll navigation for player
  playlist?: string
  playlist_collection?: string
  priority?: string
  product_checkout_card_v2?: boolean // Default value is false, once QA signs off we will swap to true and then wait ~two weeks in case customers do not like this change so we can revert in an agile manner
  product_click_use_instream_pdp?: boolean // [COM-1912] Use instream/outstream PDP on Product Card / List Item click
  product_image_carousel?: boolean // [GROW-996] Product image carousel
  product_index?: boolean // Default value is false, and is nested within the product_checkout_card_v2 FF, so to see this feature both have to be enabled
  microsites_host?: string
  qr_code?: boolean // [CS-4515] Configurable player menu
  referrer?: string
  reverse_audio_controls?: boolean // [CS-7291] Reverse the order of CC and mute buttons in the header
  reset_transform?: ResetTransformEnum
  share?: ShareEnum | null
  share_template?: string | null
  shopping_cart_style?: ShoppingCartStyle // [GROW-434] shopping bag customization for levi's
  shopping_title_disabled?: boolean // [CS-7324] whether to show the shopping wing label
  show_ads?: boolean
  show_preroll?: boolean
  showroom_id?: string // Player will get showroom feed for showroom schedule widget
  skus?: string
  sold_and_fulfilled_enabled?: boolean // [PAY-409] enabled sold and fulfilled by on PDP
  sp_outstream_tag?: string // [COM-1883] URL to VAST template with ad outstream configuration used for Criteo
  start_time?: number
  start_time_sku?: string // External ID (SKU) of a product moment to start the video at - seek to product moment
  tap_to_watch?: boolean // [CS-1685] Requires tap to watch when watching livestreams. Defaults to true.
  target?: HTMLElement | ShadowRoot | null
  theme?: string // See also `ui_theme`
  player_deck_layout?: boolean
  title?: string
  title_alignment?: PlayerTitleAlignmentEnum // [CS-5493]
  title_size?: number // [CS-5866]
  type?: WidgetTypesEnum
  ui_asset_back?: string
  ui_asset_close?: string
  ui_asset_closed_captions_disabled?: string
  ui_asset_closed_captions_enabled?: string
  ui_asset_fullscreen?: string
  ui_asset_menu?: string
  ui_asset_minimize?: string
  ui_asset_muted?: string
  ui_asset_next?: string
  ui_asset_pause?: string
  ui_asset_play?: string
  ui_asset_previous?: string
  ui_asset_product_tag?: string
  ui_asset_reaction?: string
  ui_asset_reaction_animation?: string
  ui_asset_shopping_bag?: string
  ui_asset_shopping_cart?: string
  ui_asset_unmuted?: string
  ui_border_style?: ButtonBorderStyle
  ui_button_color?: string | null
  ui_button_font_color?: string | null
  ui_font_css?: string
  ui_full_cta?: boolean
  ui_player_style?: PlayerStyle
  ui_primary_color?: string | null
  ui_share_sheet?: string
  ui_theme?: string // UI theme name configurable in the channel appearance settings.
  vast_attrs?: string
  vast_xml?: string
  video?: string
  video_id?: string
  wait_for_vast_attrs?: boolean // [QA-897] wait for vast_attrs param to be provided before fetching ad tags
  widget_config_id?: string
  widget_id?: string
  widget_presentation?: IPresentation // [CS-8031] New param to control widget presentation (eg. 'product_gallery', 'player_deck')
} & PIPParams

export enum WidgetSizeEnum {
  MEDIUM = 'medium',
  LARGE = 'large',
  SMALL = 'small',
}

export enum ProductTagPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  FALSE = 'false',
}

// TODO: Rename to EmbedFeedParams and create EmbedFeedParams as a parent to all widget params
export interface EmbedFeedParams extends Omit<IParams, 'placement'> {
  auto_embed_widget?: boolean // Dynamic param added to auto embed widget
  captions?: boolean
  minimized?: boolean
  mode?: 'row' | 'grid' | 'pinned' | string
  paginate_by?: number
  per_page?: number
  placement?: string
  thumbnail_style?: 'video' | 'dynamic'
  thumbnail_cta?: boolean // Show CTA on top of the thumbnail. Defaults to false.
  thumbnail_orientation?: 'horizontal' | 'vertical' // [CS-8437] allow setting thumbnail orientation
  thumbnail_product_card?: boolean // Show product card on top of the thumbnail. Defaults to false.
  player_deck_layout?: boolean // Show product card bellow the thumbnail. Defaults to false.
  product_tag_placement?: ProductTagPlacement // [CS-5959] allow placing shopping bag to the bottom
  size?: WidgetSizeEnum // any other string => 'medium', defaults to large
}

export interface IPlayerParams extends IParams {
  cover?: boolean // [CS-3938] cover to render storyblock's main video with 100% width and height
  dock?: boolean
  height?: string
  mode?: 'pinned' | 'fullscreen' | string
  per_page?: number
  product_card?: boolean // [CS-4595] Whether to show/hide product card on top of the video in the player. Defaults to true.
  width?: string
}

export interface IStoryblockParams extends IPlayerParams {}

export interface IHerounitParams extends IPlayerParams {
  hero_flip_order?: boolean // Default value is false until explicitly set to true
  hero_hide_countdown?: boolean // Default value is false until explicitly set to true
  hero_previous_event_promotion?: boolean // Default value is true
  hero_text_mode?: 'even' | 'stretched' // Default value is even until explicitly set to stretched
}

export interface AddToCalendarParams extends IParams {
  popup_position?: string
  upcoming_label?: string
  live_label?: string
}

export interface ShareParams extends IParams {
  popup_position?: string
  share_label?: string
}

export interface IStorylinkParams extends IParams {
  per_page?: number
}

export interface IProgrammaticPlayerParams extends IParams {
  appContextType?: AppContextType
  minimized?: boolean
}

export interface ButtonWrapperParams extends IParams {
  button_id?: string
}

export type IAnyWidgetParams =
  | EmbedFeedParams
  | IStoryblockParams
  | IHerounitParams
  | IStorylinkParams
  | IPlayerParams
  | IProgrammaticPlayerParams
  | ShareParams
  | ButtonWrapperParams

export type IAllWidgetParams = EmbedFeedParams &
  IStoryblockParams &
  IHerounitParams &
  IStorylinkParams &
  IPlayerParams &
  IProgrammaticPlayerParams &
  AddToCalendarParams &
  ShareParams &
  ButtonWrapperParams

// @TODO move interface to Widget component
export type IWidgetInitialParams = Partial<IAllWidgetParams>

/**
 *
 */
export const areStoryblockParams = (
  params: IAnyWidgetParams | undefined,
): params is IStoryblockParams => params?.type === WidgetTypesEnum.STORYBLOCK

/**
 *
 */
export const arePlayerParams = (
  params: IAnyWidgetParams | undefined,
): params is IPlayerParams => params?.type === WidgetTypesEnum.PLAYER
