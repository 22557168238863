export type AvaContextType = 'page' | 'product'

export interface AvaContext {
  key?: string
  context: string
  type: AvaContextType
}

export interface AvaGlobalState {
  contexts: Array<AvaContext>
}

/**
 *
 */
export function createAvaState(): AvaGlobalState {
  return {
    contexts: [],
  }
}
