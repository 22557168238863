import { getABTestsForTracking } from '@core/abTesting/abTesting'
import { getGlobalConfig } from '@core/globalConfig'
import { pruneNilValues } from '@core/object'
import { getGlobalStore, pageLoadIdSelector } from '@core/store'
import {
  getFWReferrerForTracking,
  locale,
  nowAndNowLocalToString,
} from '@core/tracking'
import { getUTM } from '@core/tracking/attribution/storeUTM'
import { getConsent } from '@core/tracking/consent'
import { getPageUrl } from '@core/window'
import { sanitizeSensitiveQueryParams } from '@core/window/libs/url'

/**
 * Get global event properties for pixel tracking. These form the basis of the
 * event_properties field in the pixel tracking payload.
 */
export const getGlobalEventProperties = () => {
  const pageUrl = sanitizeSensitiveQueryParams(getPageUrl() ?? '')
  const { nowLocal } = nowAndNowLocalToString()
  return pruneNilValues({
    locale,
    page_load_id: pageLoadIdSelector(getGlobalStore().getState()),
    ext_guest_id: getGlobalConfig().ext_guest_id,
    page_url: pageUrl,
    client_local_time: nowLocal,
    user_consent_level: getConsent(),
    tests: getABTestsForTracking(),
    ...getUTM(),
    ...getFWReferrerForTracking(),
  })
}
