import type { AvaGlobalStateSlice } from './slice'
import { AVA_SLICE_KEY } from './slice'

/**
 * Returns the ava context from the global state
 */
export function avaGlobalContextsSelector(state: AvaGlobalStateSlice) {
  return state[AVA_SLICE_KEY].contexts
}

/**
 * Returns the ava actions from the global state
 */
export function avaGlobalActionsSelector(state: AvaGlobalStateSlice) {
  return state[AVA_SLICE_KEY].actions
}
