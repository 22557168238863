import type { NamedSet } from 'zustand/middleware/devtools'

import type { Api_Product, Api_Product_Unit } from '@core/api'
import { logInfo } from '@core/logging'

import type {
  CartDisplayedCallback,
  CartUpdatedCallback,
  IShoppingState,
  ProductsLoadedCallback,
} from './state'

export type IShoppingAction = ReturnType<typeof createShoppingActions>

/**
 *
 */
export function createShoppingActions(set: NamedSet<IShoppingState>) {
  return {
    resetCart: (): void => set({ cart: [] }, false, 'resetCart'),
    configureCart: (payload: {
      url?: string
      currency?: string
      // TODO(GROW-440): deprecate these 2 params once Levi's switch over to `shopping_cart_style`
      addToCartText?: string
      itemInCartText?: string
      // whether or not this shopping cart integration supports the quick add features in player deck
      // and anywhere else we might want to add a product directly to the integrating platform's cart
      // instead of using our instream cart
      quickAdd?: boolean
    }): void =>
      set(
        (state) => ({
          cartUrl: payload?.url || state.cartUrl,
          cartCurrency: payload?.currency || state.cartCurrency,
          cartAddToCartText: payload?.addToCartText || state.cartAddToCartText,
          quickAdd: payload?.quickAdd || state.quickAdd,
          cartItemInCartText:
            payload?.itemInCartText || state.cartItemInCartText,
        }),
        false,
        'configureCart',
      ),
    hydrateProducts: (products: Api_Product[]): void => {
      set(
        (state) => {
          const newProductsHydrated = {
            ...state.productsHydrated,
          }
          for (const product of products) {
            newProductsHydrated[product.product_ext_id] = product
          }
          return {
            productsHydrated: newProductsHydrated,
          }
        },
        false,
        'hydrateProducts',
      )
    },
    updateUnitCartQuantity: (payload: {
      productUnit?: Api_Product_Unit
      targetQuantity?: number
      product?: Api_Product
    }): void => {
      const { productUnit, targetQuantity, product } = payload

      set(
        (state) => {
          if (productUnit) {
            let newCart: IShoppingState['cart']
            if (
              state.cart.find(
                (ci) =>
                  String(ci.unit.unit_ext_id) ===
                  String(productUnit?.unit_ext_id),
              )
            ) {
              newCart = [
                ...state.cart.map((ci) => {
                  if (
                    String(ci.unit.unit_ext_id) ===
                    String(productUnit?.unit_ext_id)
                  ) {
                    return {
                      unit: ci.unit,
                      quantity: targetQuantity || 0,
                      product: ci.product,
                    }
                  } else {
                    return ci
                  }
                }),
              ]
            } else {
              newCart = [
                {
                  unit: productUnit,
                  quantity: targetQuantity ?? 1,
                  product,
                },
                ...state.cart,
              ]
            }

            return {
              cart: newCart.filter((ci) => ci.quantity && ci.quantity > 0),
            }
          } else {
            return state
          }
        },
        false,
        'updateUnitCartQuantity',
      )
    },
    setCartUpdatedCallback: (callback: CartUpdatedCallback): void => {
      set(
        {
          cartUpdatedCallback: wrapWithLogger(callback, 'cartUpdatedCallback'),
        },
        false,
        'setCartUpdatedCallback',
      )
    },
    setCartDisplayedCallback: (callback: CartDisplayedCallback): void => {
      set(
        {
          cartDisplayedCallback: wrapWithLogger(
            callback,
            'cartDisplayedCallback',
          ),
        },
        false,
        'setCartDisplayedCallback',
      )
    },
    setProductsLoadedCallback: (callback: ProductsLoadedCallback): void => {
      set(
        {
          productsLoadedCallback: wrapWithLogger(
            callback,
            'productsLoadedCallback',
          ),
        },
        false,
        'setProductsLoadedCallback',
      )
    },
    cartUpdateStarted: () => {
      set(
        (state) => ({
          pendingCartUpdate: state.pendingCartUpdate + 1,
        }),
        false,
        'cartUpdateStarted',
      )
    },
    cartUpdateEnded: () => {
      set(
        (state) => ({
          pendingCartUpdate: state.pendingCartUpdate - 1,
          lastCartUpdateTs: Date.now(),
        }),
        false,
        'cartUpdateEnded',
      )
    },
  }
}

function wrapWithLogger<T extends (...args: Parameters<T>) => ReturnType<T>>(
  callback: T,
  name: string,
): (...args: Parameters<T>) => ReturnType<T> {
  return (...args: Parameters<T>) => {
    logInfo(`[fw-shopping] ${name}`, args)
    return callback(...args)
  }
}
