import type { NamedSet } from 'zustand/middleware'

import { createPlayerActions } from './actions'
import { type PlayerGlobalState } from './types'

/**
 * Global state for player
 */
export function createPlayerState(
  set: NamedSet<PlayerGlobalState>,
): PlayerGlobalState {
  return {
    actions: createPlayerActions(set),
    playerStates: {},
    isLivestreamJoinEventTrackedByVideoId: {},
    isUserMuted: undefined,
  }
}
