import type { PixelTrackingEndpoint } from '@core/tracking'

export const FEED_VIEW_CHANNEL: PixelTrackingEndpoint = {
  url: '/trk/feed:view_channel',
  isUserEngagement: false,
  consent: 'essential',
}

export const ONBOARDING_VIEW_DISCLOSURE: PixelTrackingEndpoint = {
  url: '/trk/onboarding:view_disclosure',
  isUserEngagement: false,
  consent: 'essential',
}

/**
 * Note: Adding an endpoint to this file requires update to
 * the Jira docs https://fwn.atlassian.net/wiki/x/SQAELQ
 */
