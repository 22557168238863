import { lens } from '@dhmk/zustand-lens'

import type { AvaGlobalAction } from './actions'
import { createAvaGlobalActions } from './actions'
import type { AvaGlobalState } from './state'
import { createAvaState } from './state'

export const AVA_SLICE_KEY = 'ava'

export interface AvaGlobalStateSlice {
  [AVA_SLICE_KEY]: AvaGlobalState & {
    actions: AvaGlobalAction
  }
}

/**
 *
 */
export function createAvaGlobalSlice(): AvaGlobalStateSlice {
  return {
    [AVA_SLICE_KEY]: lens((set) => ({
      ...createAvaState(),
      actions: createAvaGlobalActions(set),
    })),
  }
}
