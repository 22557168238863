import type { GetState } from 'zustand'
import type { NamedSet } from 'zustand/middleware'

import type { AvaGlobalStateSlice } from '@core/ava/model'
import { createAvaGlobalSlice } from '@core/ava/model'
import type { Slice as ChannelSlice } from '@core/channel/model/slice'
import { createSlice as createChannelSlice } from '@core/channel/model/slice'
import type { IDeviceSettingsSlice } from '@core/deviceSettings'
import { createDeviceSettingsSlice } from '@core/deviceSettings'
import type { IGatedVideoStateSlice } from '@core/gatedVideo/model/slice'
import { createGatedVideoSlice } from '@core/gatedVideo/model/slice'
import type { IHydrationGlobalStateSlice } from '@core/hydration/model'
import { createHydrationSlice } from '@core/hydration/model'
import type { ILiveStreamGlobalStateSlice } from '@core/livestream/model'
import { createLiveStreamSlice } from '@core/livestream/model'
import type { IPlayerGlobalStateSlice } from '@core/player/model'
import { createPlayerSlice } from '@core/player/model'
import type { IShoppingGlobalStateSlice } from '@core/shopping/model'
import { createShoppingSlice } from '@core/shopping/model'
import type { ISharedGlobalState } from '@core/store/sharedState/state'
import { createSharedGlobalState } from '@core/store/sharedState/state'

export type StateCreator = (
  set: NamedSet<IGlobalState>,
  get: GetState<IGlobalState>,
) => IGlobalState

export type IGlobalState = IHydrationGlobalStateSlice &
  IPlayerGlobalStateSlice &
  ILiveStreamGlobalStateSlice &
  ISharedGlobalState &
  IDeviceSettingsSlice &
  IShoppingGlobalStateSlice &
  IGatedVideoStateSlice &
  ChannelSlice &
  AvaGlobalStateSlice
/**
 *
 */
export const createState: StateCreator = (set) => ({
  ...createHydrationSlice(set),
  ...createPlayerSlice(),
  ...createLiveStreamSlice(),
  ...createDeviceSettingsSlice(),
  ...createShoppingSlice(),
  ...createSharedGlobalState(set),
  ...createGatedVideoSlice(),
  ...createChannelSlice(),
  ...createAvaGlobalSlice(),
  setState: (state) => set(state),
})
