import type { Api_Product, Api_Product_Unit, Api_Video } from '@core/api'

/**
 * Different from ICartContentItem, product is required in this interface.
 * This interface is used to represent the content of the shopping cart after
 * mapping them with the products. Units without a matching product will be
 * discarded.
 */
export interface ICartContentItemWithProduct {
  product: Api_Product
  unit: Api_Product_Unit
  quantity: number
}

export type ICartContent = ICartContentItem[]

export interface ICartContentItem {
  // When rendering a cart item, player will try to find the product from the
  // products downloaded from the videos or hydrated by the site. If it can't
  // find it, it will use the product from the cart item.
  // This is only needed when it is impossible to hydrate the cart.
  product?: Api_Product
  unit: Api_Product_Unit
  quantity: number
}

/**
 * A callback that triggers when Firework player attempts to update the cart. It
 * is recommended to sync with the external cart backend in the callback and
 * return the cart item quantities verified by the server.
 */
export type CartUpdatedCallback = (payload: {
  product: Api_Product
  productUnit: Api_Product_Unit
  quantity: number
  previousQuantity: number
  video: Api_Video | null
}) => Promise<number>

export interface HydratedCartItem {
  product: Api_Product
  unitId: string
  quantity: number
}
/**
 * A callback that triggers when Firework player displays the cart (or cart
 * icon). It is recommended to sync the external cart in the callback. The
 * callback should return the latest product data of the cart items and the cart
 * item quantities.
 */
export type CartDisplayedCallback = () => Promise<
  HydratedCartItem[] | undefined
>

/**
 * A callback that triggers when Firework player finishes loading products. It
 * is recommended to hydrate products in this callback if the OMS has up-to-date
 * product information or things that are customized to local users.
 *
 * @returns A promise that resolves to a list of hydrated products if all the
 *     products can be loaded at once. A list of promises that resolve to
 *     individual product if the products need to be loaded one by one.
 */
export type ProductsLoadedCallback = (payload: {
  video: Api_Video | null
  products: Api_Product[]
}) => Promise<Api_Product[] | undefined>

export interface IShoppingState {
  cart: ICartContent
  cartCurrency: string
  cartUrl: string
  cartAddToCartText: string
  cartItemInCartText: string
  productsHydrated: Record<string, Api_Product>
  cartUpdatedCallback?: CartUpdatedCallback
  cartDisplayedCallback?: CartDisplayedCallback
  productsLoadedCallback?: ProductsLoadedCallback
  pendingCartUpdate: number // number of pending cart updates.
  lastCartUpdateTs: number // timestamp of the last time the cart was updated.
  quickAdd: boolean
}

/**
 *
 */
export function createShoppingState(): IShoppingState {
  return {
    cart: [],
    cartCurrency: '',
    cartUrl: '',
    cartAddToCartText: '',
    cartItemInCartText: '',
    productsHydrated: {},
    pendingCartUpdate: 0,
    lastCartUpdateTs: 0,
    quickAdd: false,
  }
}
